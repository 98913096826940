import { ChangeDetectorRef, Component, computed, ElementRef, EventEmitter, inject, Input, OnInit, Output, signal } from "@angular/core";
import { SchemaRendererComponent } from "../schema-renderer/schema-renderer.component";
import { FormsModule } from "@angular/forms";
import { InternalMessageService } from "@services/internalMessage.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-deploy",
  templateUrl: "./deploy.component.html",
  styleUrls: ["./deploy.component.scss"],
  imports: [FormsModule, DatePipe],
  providers: [],
  standalone: true,
})
export class DeployComponent extends SchemaRendererComponent implements OnInit {
  @Input() _type: any;
  @Input() env: any;
  @Input() hugeType: any;
  @Input() override data: any;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  public schemas = signal<any>([]);
  ElementRef: ElementRef = inject(ElementRef)

  private selectAllcheckbox: boolean = false;
  public deployComment: any = "";
  public isSaved: boolean = false;
  public Timer: Date = new Date()
  public curTimer: Date = new Date()
  private config: any;



  constructor() {
    super()
    this.deployComment = computed(() => {
      let date = new Date();
      let formattedDate = `${date.getDate()}/${date.getMonth() + 1
        }/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      return `${this.env} - ${this.schemas().filter((el: any) => el.select).map((el: any) => el.title).join(", ")} - ${this.StoreService.getUser().email} - ${formattedDate}`
    })
  }

  timerCount() {
    const local = localStorage.getItem(`${this.StoreService.getFromStore_Static("current_project")?.alias}_${this.env}_min`)
    if (local) {
      this.Timer = new Date(local)
      if (this.Timer > new Date) {
        return true
      } else {
        localStorage.removeItem(`${this.StoreService.getFromStore_Static("current_project")?.alias}_${this.env}_min`)
        return true
      }
    } else {
      return false
    }
  }

  override ngOnInit(): void {

    this.ApiService.Getmethod(`config/deploy_cooldown/dev`).subscribe((response) => {
      this.config = response.data
    })

    const { alias } = this.StoreService.getFromStore_Static("current_project");
    this.ApiService.Getmethod(`content/deploy/list-schema/${alias}/${this.env}`).subscribe((response) => {
      for (const item of response.schemas) {
        item["select"] = false;
        item["touched"] = false;
      }
      if (response.schemas.length > 10) {
        let x = 200 * (response.schemas.length / 10);
        let y = response.schemas.length % 10 > 0 ? 200 : 0;
        //@ts-ignore
        this.ElementRef.nativeElement.querySelector(".deployCont")["style"].width = x + y + "px";
      }

      if (this._type) {
        for (let item of response.schemas) {
          if (this._type == item.type) {
            item.select = true;
            item.touched = true;
            this.selectCheckBox(item);
          }
        }
      }
      this.schemas.set(response.schemas);
      this.cdref.detectChanges();
    });
  }

  override ngOnDestroy() { }
  touched(checkbox: any) {
    console.log(checkbox)
    this.schemas.update((current: any) => {
      const field = current.find((el: any) => el.type == checkbox.type)
      field.touched = !field.touched;
      field.select = !field.select;
      return [...current];
    });

    // checkbox.touched = true;
    // this.selectCheckBox(checkbox);
  }

  selectCheckBox(checkbox: any) {
    setTimeout(() => {
      for (let item of this.schemas()) {
        this.deepLink(checkbox, item);
      }
    }, 0);
  }

  deepLink(checkbox: any, item: any) {
    for (let sTypes of checkbox.selectTypes) {
      if (item.type == sTypes) {
        if (!item.touched) item.select = checkbox.select;
        if (item.selectTypes.length > 0) {
          this.selectCheckBox(item);
        }
      }
    }
  }

  selectAll() {
    this.selectAllcheckbox = !this.selectAllcheckbox;
    this.schemas.update((current: any) => {
      current.forEach((el: any) => {
        el.touched = this.selectAllcheckbox
        el.select = this.selectAllcheckbox
      })
      return [...current];
    });
    for (let item of this.schemas()) {
      item.select = this.selectAllcheckbox;
    }
  }


  deploy() {
    if (this.hugeType == "deploy") {
      const currentDate = new Date();
      this.Timer = new Date(currentDate.getTime() + ((this.config[this.StoreService.getFromStore_Static("current_project")?.alias]?.deploy_cooldown[`${this.env}_min`] || 10) * 60 * 1000))
      localStorage.setItem(`${this.StoreService.getFromStore_Static("current_project")?.alias}_${this.env}_min`, this.Timer.toISOString())

      let dataToSend;
      console.log(this.config[this.StoreService.getFromStore_Static("current_project")?.alias].deploy_cooldown[`${this.env}_min`])

      if (this.data?.list) {
        dataToSend = {
          comment: this.deployComment(),
          types: this.schemas()
            .map((el: any) => {
              if (el.select) return el.type;
            })
            .filter((el: any) => el),
          type_limit: {
            type: this._type,
            list: this.data.list,
          },
        };
      } else {
        dataToSend = {
          comment: this.deployComment(),
          types: this.schemas()
            .map((el: any) => {
              if (el.select) return el.type;
            })
            .filter((el: any) => el),
        };
      }
      this.isSaved = true;
      this.ApiService.Postmethod(
        `content/deploy/data/${this.StoreService.getFromStore_Static("current_project")?.alias}/${this.env}`,
        dataToSend
      ).subscribe(
        (response) => {
          let message = "";
          for (const [key, value] of Object.entries(response)) {
            message = message + `<p>${this.env.toUpperCase()} - Deploy successful! Section: <strong>${key}</strong> Changed items: ${value}</p>`;
          }
          console.log(message)

          this.InternalMessageService.successMessage(`${message}`);
          this.isSaved = false;
          this.closeDialog.emit();
        },
        (err) => {
          console.log(err);
          this.isSaved = false;
        }
      );
    }
    if (this.hugeType == "clearCash") {
      this.isSaved = true;
      let dataToSend = {
        types: this.schemas()
          .map((el: any) => {
            if (el.select) return el.type;
          })
          .filter((el: any) => el),
      };
      this.ApiService.Postmethod(`cash/client/${this.StoreService.getFromStore_Static("current_project")?.alias}/${this.env}`, dataToSend).subscribe(
        (response) => {
          this.InternalMessageService.successMessage(`Cash maybe cleared at least you tried`);
          this.isSaved = false;
          this.closeDialog.emit();
        },
        (err) => {
          console.log(err);
          this.isSaved = false;
        }
      );
    }
    if (this.hugeType == "forceClearCache") {
      this.isSaved = true;
      let dataToSend = {
        types: this.schemas()
          .map((el: any) => {
            if (el.select) return el.type;
          })
          .filter((el: any) => el),
        forceClear: true
      };

      this.ApiService.Postmethod(`cash/client/${this.StoreService.getFromStore_Static("current_project")?.alias}/${this.env}`, dataToSend).subscribe(
        (response) => {
          this.InternalMessageService.successMessage(`FORCE!!! Cash maybe cleared at least you tried`);
          this.isSaved = false;
          this.closeDialog.emit();
        },
        (err) => {
          console.log(err);
          this.isSaved = false;
        }
      );
    }
  }
}
